import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorNotification from 'shared/error/errorNotification';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

const AppRoutes = () => (
  <>
    <ErrorNotification />
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route
        path="/feedback"
        component={lazy(() => import('pages/surveyUI'))}
      />
      <PublicRoute
        path="/login"
        component={lazy(() => import('pages/auth/login'))}
      />
      <PublicRoute
        path="/registration"
        component={lazy(() => import('pages/auth/register'))}
      />
      <PublicRoute
        path="/verifyEmail"
        component={lazy(() => import('pages/auth/verifyEmail'))}
      />
      <PublicRoute
        exact
        path="/confirmEmail"
        component={lazy(() => import('pages/auth/confirmEmail'))}
      />
      <PublicRoute
        exact
        path="/forgetPassword"
        component={lazy(() => import('pages/auth/forgօtPassword'))}
      />
      <PublicRoute
        path="/resetPassword"
        component={lazy(() => import('pages/auth/resetPass'))}
      />
      <Route
        path="/forgotPassword"
        component={lazy(() => import('pages/auth/tokenIsValid'))}
      />
      <PublicRoute
        path="/changePass"
        component={lazy(() => import('pages/auth/changePass'))}
      />
      <PrivateRoute
        path="/workspaces"
        component={lazy(() => import('pages/workspaces'))}
      />
      <PrivateRoute
        path="/profile"
        component={lazy(() => import('pages/profile'))}
      />
      <PrivateRoute
        exact
        path="/project"
        component={lazy(() => import('pages/project/surveys'))}
      />
      <PrivateRoute
        exact
        path="/project/surveys"
        component={lazy(() => import('pages/project/surveys'))}
      />
      <PrivateRoute
        path="/project/surveys/pages/:surveyId/questions"
        component={lazy(() => import('pages/project/surveys/pages/questions'))}
      />
      <PrivateRoute
        path="/project/surveys/pages/:surveyId/design"
        component={lazy(() => import('pages/project/surveys/pages/design'))}
      />
      <PrivateRoute
        path="/project/surveys/pages/:surveyId/logic"
        component={lazy(() => import('pages/project/surveys/pages/logic'))}
      />
      <PrivateRoute
        path="/project/endpoints"
        component={lazy(() => import('pages/project/endpoints'))}
      />
      <PrivateRoute
        path="/project/ads"
        component={lazy(() => import('pages/project/ads/index'))}
      />
      <PrivateRoute
        path="/project/users"
        component={lazy(() => import('pages/project/users'))}
      />
      <PrivateRoute
        path="/project/integrations"
        component={lazy(() => import('pages/project/integrations'))}
      />
      <PrivateRoute
        path="/project/surveys/reports/:surveyId"
        component={lazy(() => import('pages/project/surveys/reports/report'))}
      />
      <Route component={lazy(() => import('./notFound'))} />
    </Switch>
  </>
);

export default AppRoutes;
